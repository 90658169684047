import { BadgeStateRole } from '@aimmo/design-system/aim-tag-model/src';
import { InjectionToken } from '@angular/core';
import { AbstractControlDirective, NgControl } from '@angular/forms';
import { AimFormLayoutComponent } from '../src/aim-form-layout.component';

export abstract class AimFormLayoutControl<T> {
  public readonly controlType?: string;
  public readonly ngControl?: NgControl | AbstractControlDirective | null;
  public readonly id: string;
  public readonly placeholder: string;
  public readonly required: boolean;
  public readonly empty: boolean;
  public readonly focused: boolean;
  public readonly disabled: boolean;
  public readonly errorState: boolean;
  // TODO: 추후 상황에 따라 추가 예정.
  // public value: T | null;
  // public readonly stateChanges: Observable<void>;
  // public readonly userAriaDescribedBy?: string;

  public abstract focus(options?: FocusOptions): void;
}

export const FormLayoutAppearance = {
  basic: 'basic',
  ghost: 'ghost',
} as const;

export type FormLayoutAppearanceType = typeof FormLayoutAppearance[keyof typeof FormLayoutAppearance];

export const FormLayoutState = {
  pass: BadgeStateRole.pass,
  warning: BadgeStateRole.warning,
  error: BadgeStateRole.error,
} as const;

export type FormLayoutStateType = typeof FormLayoutState[keyof typeof FormLayoutState];

export const FormLayoutHint = {
  none: '',
  description: 'description',
  count: 'count',
  all: 'all'
} as const;

export type FormLayoutHintType = typeof FormLayoutHint[keyof typeof FormLayoutHint];


export interface AimParentFormLayout {
  control?: AimFormLayoutControl<any>;
}

export const AIM_PARENT_FORM_LAYOUT = new InjectionToken<AimParentFormLayout>('AIM_PARENT_FORM_LAYOUT');

export const FormLayoutHintSize = {
  small: 'small',
  medium: 'medium',
} as const;

export type FormLayoutHintSizeType = typeof FormLayoutHintSize[keyof typeof FormLayoutHintSize];


export const AimLabelSize = {
  medium: 'medium',
  large: 'large',
} as const;

export type AimLabelSizeType = typeof AimLabelSize[keyof typeof AimLabelSize];

export const AimLabelRequire = {
  show: 'show',
  hide: 'hide',
  auto: 'auto'
} as const;

export type AimLabelRequireType = typeof AimLabelRequire[keyof typeof AimLabelRequire];

export const AIM_FORM_LAYOUT = new InjectionToken<AimFormLayoutComponent>('AimFormLayout');
