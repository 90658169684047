export const BadgeNotiSize = {
  small: 'small',
  medium: 'medium',
} as const;
export type BadgeNotiSizeType = keyof typeof BadgeNotiSize;

export const BadgeNotiDotSize = {
  small: 'small',
  medium: 'medium'
} as const;
export type BadgeNotiDotSizeType = keyof typeof BadgeNotiDotSize;

export const BadgeNotiRole = {
  default: 'default',
  update: 'update',
  processing: 'processing'
} as const;
export type BadgeNotiRoleType = keyof typeof BadgeNotiRole;

export const BadgeNotiIconRole = {
  new: 'new'
} as const;
export type BadgeNotiIconRoleType = keyof typeof BadgeNotiIconRole;

export const BadgeCategory = {
  task: 'task',
  class: 'class'
} as const;
export type BadgeCategoryType = keyof typeof BadgeCategory;

export const BadgeTaskRole = {
  waiting: 'waiting',
  ready: 'ready',
  in_progress: 'in_progress',
  done: 'done'
} as const;

export type BadgeTaskRoleType = keyof typeof BadgeTaskRole;

export const BadgeIconCategory = {
  state: 'state',
  smartLabeling: 'smart-labeling',
  annotation: 'annotation',
} as const;
export type BadgeIconCategoryType = typeof BadgeIconCategory[keyof typeof BadgeIconCategory];

export const BadgeStateRole = {
  pass: 'pass',
  reject: 'reject',
  rework: 'rework',
  warning: 'warning',
  error: 'error',
} as const;
export type BadgeStateRoleType = keyof typeof BadgeStateRole;

export const BadgeSmartLabelingRole = {
  processing: 'processing',
  doneSuccess: 'done-success',
  doneComplex: 'done-complex',
  doneNone: 'done-none',
  doneError: 'done-error',
  notApplied: 'not-applied',
} as const;
export type BadgeSmartLabelingRoleType = typeof BadgeSmartLabelingRole[keyof typeof BadgeSmartLabelingRole];

export const BadgeAnnotationRole = {
  bbox: 'bbox',
  brush: 'brush',
  classification: 'classification',
  multiClassification: 'multi-classification',
  cuboid: 'cuboid',
  flatCuboid: 'cuboid-plain',
  entity: 'entity',
  frameRange: 'frame-range',
  landmark: 'landmark',
  orientedBbox: 'oriented-bbox',
  polySegmentation: 'poly-segmentation',
  polygon: 'polygon',
  polyline: 'polyline',
  polylinePoint: 'polyline-point',
  polylinePoint3d: 'polyline-point-3d',
  point: 'point',
  segmentation: 'segmentation',
  text: 'text',
  temp: 'null'
} as const;
export type BadgeAnnotationRoleType = typeof BadgeAnnotationRole[keyof typeof BadgeAnnotationRole];
